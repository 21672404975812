import { mapGetters } from "vuex";
import { Flicking, MOVE_TYPE } from "@egjs/vue-flicking";
import "@egjs/vue-flicking/dist/flicking.css";

export default {
  name: "banner",
  data() {
    return {
      MOVE_TYPE,
      autoplayInterval: null,
      autoplayIntervalSeconds: 10,
      screenWidth: window.innerWidth,
    };
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.$refs.videoSlide) return;
      this.$refs.videoSlide.forEach(item => {
        item.addEventListener("play", () => {
          console.log("START PLAY =====");
          this.stopAutoPlay();
        });
        item.addEventListener("ended", () => {
          console.log("END PLAY =====");
          this.nextSlide();
          this.startAutoplay();
        });
      });
    });
  },
  components: {
    Flicking,
  },
  created() {
    this.startAutoplay();
  },
  beforeDestroy() {
    clearInterval(this.autoplayInterval);
  },
  computed: {
    ...mapGetters({
      home: "pages/page",
    }),
  },
  methods: {
    prevSlide() {
      if (!this.$refs.flicking.animating) {
        this.$refs.flicking.prev();
      }
    },
    nextSlide() {
      if (!this.$refs.flicking.animating) {
        this.$refs.flicking.next();
      }
    },
    startAutoplay() {
      this.autoplayInterval = setInterval(() => {
        if (!this.$refs.flicking.animating) {
          this.$refs.flicking.next();
        }
      }, this.autoplayIntervalSeconds * 1000);
    },
    stopAutoPlay() {
      clearInterval(this.autoplayInterval);
    },

    videoSliderStarter(data) {
      const currentPanelIsVideo = data.currentTarget.visiblePanels[0].element.querySelector("video");

      if (currentPanelIsVideo) {
        this.stopAutoPlay();
        currentPanelIsVideo.load();
        currentPanelIsVideo.play();
      }
    },
    moveSlider(data) {
      this.videoSliderStarter(data);
    },
    addEventForEveryVideoSlide(data) {
      this.videoSliderStarter(data);
    },
  },
};
